'use strict';

/**
 * Class that tracks the network communciation across the app.
 * @constructor
 */

function AudioFeatureCheck() {
    var _features = MusicServerEnum.Features,
        __name = "AudioFeatureCheck";
    return {
        set name(newVal) {
            __name = newVal;
        },

        get name() {
            return __name;
        },

        /**
         * Updates the feature list.
         * @param serverInfo    contains the firmware and the api version.
         */
        update: function updateFeatures(serverInfo) {
            var firmwareVersion = new ConfigVersion(serverInfo.firmwareVersion);
            var apiVersion = new ConfigVersion(serverInfo.apiVersion.join('.'));

            for (var featureKey in _features) {
                if (_features.hasOwnProperty(featureKey)) {
                    var apiOkay = true;
                    var firmwareOkay = true;

                    if (_features[featureKey].hasOwnProperty("api")) {
                        apiOkay = apiVersion.greaterThanOrEqualTo(_features[featureKey].api);
                    }

                    if (_features[featureKey].hasOwnProperty("firmware")) {
                        firmwareOkay = firmwareVersion.greaterThanOrEqualTo(_features[featureKey].firmware);
                    }

                    this[featureKey] = apiOkay && firmwareOkay;
                }
            }
        },

        /**
         * Returns a set where each feature is a key that has either true or false as value. Depending on if this
         * feature is supported or not.
         * @param firmware
         * @param api
         * @returns {{}}
         */
        getFeatureSetForVersion: function getFeatureSetForVersion(firmware, api) {
            var features = {},
                firmwareVersion = new ConfigVersion(firmware),
                apiVersion = new ConfigVersion(api.join('.'));

            for (var featureKey in _features) {
                if (_features.hasOwnProperty(featureKey)) {
                    var apiOkay = true;
                    var firmwareOkay = true;

                    if (_features[featureKey].hasOwnProperty("api")) {
                        apiOkay = apiVersion.greaterThanOrEqualTo(_features[featureKey].api);
                    }

                    if (_features[featureKey].hasOwnProperty("firmware")) {
                        firmwareOkay = firmwareVersion.greaterThanOrEqualTo(_features[featureKey].firmware);
                    }

                    features[featureKey] = apiOkay && firmwareOkay;
                }
            }

            return features;
        }
    };
}
